.centerBox {
  margin: 50px;
  padding: 40px 50px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.hCenter {
  margin: 0 auto !important;
  float: none;
}

.vCenter {
  margin: auto 0 !important;
  float: none;
}
.marginTop {
  margin-top: 20px;
}
.marginTop10 {
  margin-top: 10px;
}
.marginTop5{
  margin-top: 5px;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btnPrimary {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
  margin-bottom: 6px;
  margin-left: 6px;
  float: right;
}

.viewTask:hover {
  background-color: inherit;
}

.lblWrapper {
  display: flex;
  button {
    margin-bottom: 0;
  }
}
.lblWrapper label:nth-child(odd) {
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  color: #000;
  font-weight: 500;
  padding: 3px;
  width: 15%;
  margin-bottom: 0px;
}
.lblWrapper label:nth-child(even) {
    border: 1px solid #e8e8e8;
    color: #595959;
    font-weight: 500;
    padding: 3px;
    width: 85%;
    margin-bottom: 0px;
}

.toBeConfirmed .ant-checkbox-wrapper {
  width: 100%!important;
}

.loginStyle{
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  background-color: #bdc7d8;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.logo{
  height: 40px;
}
.lineImage{
  margin-top: 10px;
  margin-bottom: 7px;

  height:5px;
}
.loginHeader{
  font-family: 'Open Sans', arial;
  color: #555;
  font-size: 18px;
  font-weight: 400;
  text-align: left!important;
}
/*for forgot password and login button*/
.loginFooter{
  display: flex;
  justify-content: space-between;

}
.loginFooter a:hover{
  text-decoration: none;
  background-color: white;
}
.email-forgot:hover{
    text-decoration: none;
    background-color: white;
}

.home-link a:hover{
  text-decoration: none;
  background-color: white;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items:center;
  border-top: 5px solid #010080;
  border-bottom: 5px solid #010080;
  margin: 0px;
  padding: 0px;
}
.ticketId{
  color:red;
  font-size: 18px;
  font-weight: bold;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.jobSheetul{
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  margin-top: 5px;

}
.jobSheetli{
  border-radius: 2px;
  //background: #f4f4f4;
  margin-bottom: 2px;
  //border-left: 2px solid #e6e7e8;
  color: #444;
}
.logoConfirm{
  font-size: 32px;
  font-weight: bold;
}
.faceStyle{
  display: flex;
  justify-content: center;
  align-items:center;
}
.address{
  //display: flex;
  //justify-content: space-between;
  //align-items:center;
  font-size: 18px;
  padding: 8px 0;
  border-bottom: 5px solid #010080;
}
.fontBold{
  font-weight: bold;
  padding-left: 2px;
}
.jobsheetContact{
  border-top: 1px solid #f4f4f4;
  marginTop:10px;
  border-bottom: 5px solid #010080;
}
.jobsheetContactItem{
  display: inline-block;
  width: 20%;
  margin: 15px 0;
  //border-right: 1px solid blue;
  height: 100%;
}
.contactCard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.jobsheetContactItem1{
  display: inline-block;
  width: 20%;
  margin: 15px 0;
  border-right: none;
  height: 100%;
}
.measurePerformance tr th{
  border-left: 1px solid #e8e8e8;
}
.underLineTextStyle{
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  width:28%;
}

.underLineStyle{
  border-bottom: 1px solid #808080;
  display: inline-block;
  width:70%;
}
.job_sheet_signature{
  width: 100%;
}
.field_name{
  font-weight: bold;
  text-align: right;
  white-space: nowrap ! important;
}
.field_value{
  border-bottom: 1px solid #808080;
  width: 34%;
}

.imgStyle{
  padding:10px 10px 10px 10px;
  margin-left:10px;
  border:1px #cccccc solid;
  border-radius: 2px;
}
.imgSelectStyle{
  padding:10px 10px 10px 10px;
  margin-left:10px;
  background-color: #40a9ff;
  border:1px #40a9ff solid;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top:8px;
}
.imgUntouchedSelectStyle{
  padding:10px 10px 10px 10px;
  margin-left:10px;
  background-color: #fff;
  border:1px #fff solid;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top:8px;
}

span.glyphicon-ok {
  color: green;
  font-size: 12px;
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.authorisedStyle:focus{
  text-decoration: none;
}
.authorisedStyle:hover{
  text-decoration:none;
  background-color: #fff;
}


.NavHeadContainer{
  background-color: #3c8dbc;
}
.NavHeadMenu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color:#fff;
  height:52px;
}
 .icon {
  color: #fff;
   display: inline-block;
   padding:16px;
   font-size:20px;
   text-decoration: none;
}
.icon:active{
  color: #fff;
  display: inline-block;
  padding:16px;
  font-size:20px;
  text-decoration: none;
}
.icon:hover{
  color: #fff;
  display: inline-block;
  padding:16px;
  font-size:20px;
  text-decoration: none;
}
.LeftNavHeadMenu{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.LeftNavHeadMenu a {
  display:inline-block;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  font-size: 12px;

}
.LeftNavHeadMenu a:hover {
  color: #fff;
}
.logoContainer {
  float: left;
  padding: 12px 11px 0px 5px;
}
.logo:link {
  color: #fff;
  text-decoration: none!important;
}
.logo {
  display:inline-block;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  /* padding:15px 5px 19px 0px; */
  height: 100%;
}
#app .ant-tabs-nav .ant-tabs-tab {
  height:auto;
}
.RightNavHeadMenu{
  font-size: 12px;
  color: #fff;
}
.RightNavHeadMenu a {
  display:inline-block;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  font-size: 12px;
  height: 100%;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #3c8dbc;
  color:#fff;
  min-width: 280px;
  //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  //padding: 12px 16px 12px 2px;
  margin-left:15px;
  z-index: 1;
}
.LeftNavHeadMenu .dropdown-content a{
  margin: 0;
  padding:10px;
}
.dropdown-content-top{
  display: block;
  height: 5px;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.userWelcomeName {
  padding: 5px;
  color: white;
}
a{ text-decoration: none;}
a:hover { text-decoration: none; background-color: rgb(53, 124, 165);}
a:active {  text-decoration: none;background-color: rgb(53, 124, 165);}
a:visited {  text-decoration: none;}

.responsiveSecond{
  display:none;
}


@media screen and (max-width: 1220px) {
  .userWelcomeName{
    display: block;
  }
  .responsiveSecond i {
    font-size: 22px;
  }
}





*{
  padding: 0;
  margin: 0;
}


