.loginStyle{
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  background-color: #bdc7d8;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.logo{
  height: 40px;
}
.lineImage{
  margin-top: 10px;
  margin-bottom: 7px;

  height:5px;
}
.loginHeader{
  font-family: 'Open Sans', arial;
  color: #555;
  font-size: 18px;
  font-weight: 400;
  text-align: left!important;
}
/*for forgot password and login button*/
.loginFooter{
  display: flex;
  justify-content: space-between;

}
.loginFooter a:hover{
  text-decoration: none;
  background-color: white;
}
.email-forgot:hover{
    text-decoration: none;
    background-color: white;
}

.home-link a:hover{
  text-decoration: none;
  background-color: white;
}