span.glyphicon-ok {
  color: green;
  font-size: 12px;
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.authorisedStyle:focus{
  text-decoration: none;
}
.authorisedStyle:hover{
  text-decoration:none;
  background-color: #fff;
}