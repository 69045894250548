.header{
  display: flex;
  justify-content: space-between;
  align-items:center;
  border-top: 5px solid #010080;
  border-bottom: 5px solid #010080;
  margin: 0px;
  padding: 0px;
}
.ticketId{
  color:red;
  font-size: 18px;
  font-weight: bold;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.jobSheetul{
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  margin-top: 5px;

}
.jobSheetli{
  border-radius: 2px;
  //background: #f4f4f4;
  margin-bottom: 2px;
  //border-left: 2px solid #e6e7e8;
  color: #444;
}
.logoConfirm{
  font-size: 32px;
  font-weight: bold;
}
.faceStyle{
  display: flex;
  justify-content: center;
  align-items:center;
}
.address{
  //display: flex;
  //justify-content: space-between;
  //align-items:center;
  font-size: 18px;
  padding: 8px 0;
  border-bottom: 5px solid #010080;
}
.fontBold{
  font-weight: bold;
  padding-left: 2px;
}
.jobsheetContact{
  border-top: 1px solid #f4f4f4;
  marginTop:10px;
  border-bottom: 5px solid #010080;
}
.jobsheetContactItem{
  display: inline-block;
  width: 20%;
  margin: 15px 0;
  //border-right: 1px solid blue;
  height: 100%;
}
.contactCard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.jobsheetContactItem1{
  display: inline-block;
  width: 20%;
  margin: 15px 0;
  border-right: none;
  height: 100%;
}
.measurePerformance tr th{
  border-left: 1px solid #e8e8e8;
}
.underLineTextStyle{
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  width:28%;
}

.underLineStyle{
  border-bottom: 1px solid #808080;
  display: inline-block;
  width:70%;
}
.job_sheet_signature{
  width: 100%;
}
.field_name{
  font-weight: bold;
  text-align: right;
  white-space: nowrap ! important;
}
.field_value{
  border-bottom: 1px solid #808080;
  width: 34%;
}

.imgStyle{
  padding:10px 10px 10px 10px;
  margin-left:10px;
  border:1px #cccccc solid;
  border-radius: 2px;
}
.imgSelectStyle{
  padding:10px 10px 10px 10px;
  margin-left:10px;
  background-color: #40a9ff;
  border:1px #40a9ff solid;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top:8px;
}
.imgUntouchedSelectStyle{
  padding:10px 10px 10px 10px;
  margin-left:10px;
  background-color: #fff;
  border:1px #fff solid;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top:8px;
}
