.centerBox {
  margin: 50px;
  padding: 40px 50px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hCenter {
  margin: 0 auto !important;
  float: none;
}

.vCenter {
  margin: auto 0 !important;
  float: none;
}
.marginTop {
  margin-top: 20px;
}
.marginTop10 {
  margin-top: 10px;
}
.marginTop5{
  margin-top: 5px;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btnPrimary {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
  margin-bottom: 6px;
  margin-left: 6px;
  float: right;
}

.viewTask:hover {
  background-color: inherit;
}

.lblWrapper {
  display: flex;
  button {
    margin-bottom: 0;
  }
}
.lblWrapper label:nth-child(odd) {
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  color: #000;
  font-weight: 500;
  padding: 3px;
  width: 15%;
  margin-bottom: 0px;
}
.lblWrapper label:nth-child(even) {
    border: 1px solid #e8e8e8;
    color: #595959;
    font-weight: 500;
    padding: 3px;
    width: 85%;
    margin-bottom: 0px;
}

.toBeConfirmed .ant-checkbox-wrapper {
  width: 100%!important;
}
