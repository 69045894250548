

.NavHeadContainer{
  background-color: #3c8dbc;
}
.NavHeadMenu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color:#fff;
  height:52px;
}
 .icon {
  color: #fff;
   display: inline-block;
   padding:16px;
   font-size:20px;
   text-decoration: none;
}
.icon:active{
  color: #fff;
  display: inline-block;
  padding:16px;
  font-size:20px;
  text-decoration: none;
}
.icon:hover{
  color: #fff;
  display: inline-block;
  padding:16px;
  font-size:20px;
  text-decoration: none;
}
.LeftNavHeadMenu{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.LeftNavHeadMenu a {
  display:inline-block;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  font-size: 12px;

}
.LeftNavHeadMenu a:hover {
  color: #fff;
}
.logoContainer {
  float: left;
  padding: 12px 11px 0px 5px;
}
.logo:link {
  color: #fff;
  text-decoration: none!important;
}
.logo {
  display:inline-block;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  /* padding:15px 5px 19px 0px; */
  height: 100%;
}
#app .ant-tabs-nav .ant-tabs-tab {
  height:auto;
}
.RightNavHeadMenu{
  font-size: 12px;
  color: #fff;
}
.RightNavHeadMenu a {
  display:inline-block;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  font-size: 12px;
  height: 100%;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #3c8dbc;
  color:#fff;
  min-width: 280px;
  //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  //padding: 12px 16px 12px 2px;
  margin-left:15px;
  z-index: 1;
}
.LeftNavHeadMenu .dropdown-content a{
  margin: 0;
  padding:10px;
}
.dropdown-content-top{
  display: block;
  height: 5px;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.userWelcomeName {
  padding: 5px;
  color: white;
}
a{ text-decoration: none;}
a:hover { text-decoration: none; background-color: rgb(53, 124, 165);}
a:active {  text-decoration: none;background-color: rgb(53, 124, 165);}
a:visited {  text-decoration: none;}

.responsiveSecond{
  display:none;
}


@media screen and (max-width: 1220px) {
  .userWelcomeName{
    display: block;
  }
  .responsiveSecond i {
    font-size: 22px;
  }
}




